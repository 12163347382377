<template>
  <div>
    <ThesisForm />
  </div>
</template>

<script>
  export default {
    mounted() {
      // add scroll to on even choice button + last question scrolls to CTA banner under quiz
      const questions = document.querySelectorAll('.question');
      questions.forEach((question, i) => {
        const choices = [
          ...question.querySelectorAll('input'),
          ...question.querySelectorAll('.tf-btn'),
        ];
        choices.forEach((choice) => {
          choice.addEventListener('click', () => {
            questions[i + 1].scrollIntoView({
              behavior: 'smooth',
            });
          });
        });
      });
      // to account for placeholder question in some quizzes
      const lastQuestionNumber = window.location.pathname.includes(
        '/sleep/overview'
      )
        ? questions.length - 2
        : questions.length - 1;
      questions[lastQuestionNumber].addEventListener('click', () => {
        document
          .querySelector('.bottom-bar-show')
          .scrollIntoView({ behavior: 'smooth' });
      });
    },
    created() {
      this.$store.dispatch('INITIALIZE_STORE', this.$quizData);
      this.$store.dispatch(
        '/results/INITIALIZE_RESULTS',
        this.$quizData.sequences
      );
    },
  };
</script>
